@import url("https://fonts.googleapis.com/css2?family=Tinos:ital,wght@0,400;0,700;1,400;1,700&display=swap");

/* @font-face {
  font-family: "Arial";
  src: url("./assets/fonts/arial.tff") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-button::after {
  margin-right: auto;
  margin-left: inherit !important;
}

.form-select {
  background-position: left 0.75rem center !important;
}

.accordion-button {
  text-decoration: none;
}

.modal-header .btn-close {
  margin: inherit !important;
  margin-right: auto;
}
