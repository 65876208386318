@media screen {
  .printOnly {
    display: none;
  }
}

@media print {
  .printOnly {
    display: block;
  }
}
