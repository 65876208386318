.Receipt {
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  width: 650px;
  margin-inline: auto;
  margin-top: 10px;
}

.Gt__Logo {
  display: flex;
  justify-content: space-between;
}

.Gt__Logo img {
  width: 100px;
  height: 100px;
}

.Gt__Logo .Serial__Number {
  color: red;
  /* transform: rotate(270deg); */
}

.Receipt h2,
.Receipt h3 {
  text-align: center;
}

.Amount {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Field__Section,
.Section {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
}

.Section {
  justify-content: space-between;
}

.Section .Field__Section {
  margin-left: 15px;
}

.Field__Section label {
  margin-left: 10px;
}

.Field__Section p {
  margin-bottom: 0;
}

.Footer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.Footer .Owners {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media print {
  .PageBreak {
    page-break-after: always;
  } /* page-break-after works, as well */
}
