.Login__Page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* This ensures the form is centered within the viewport height */
}

.Login__FormContainer {
  text-align: center;
  max-width: 25rem;
  width: 100%;
}

.Login__Form {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.Login__Form h2 {
  color: #111927;
  font-family: Open Sauce Sans, sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.4;
}

.Login__FormGroup {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  align-items: flex-start;
}

.Login__FormGroup input {
  min-height: 3rem;
  color: #111927;
  background-color: #f3f4f6;
  border: 1px #000;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  width: 100%;
}

.Login__FormGroup label {
  color: #6c737f;
  font-family: Open Sauce Sans, sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.Btn__Black {
  grid-column-gap: 6px;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0b1a35 !important;
  border: 1.5px solid #0b1a35 !important;
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  margin-right: 0;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  text-decoration: none;
  transition: border-color 0.2s, background-color 0.2s;
  display: flex;
  font-size: 1rem;
}
