.Header {
}

.Header h1 {
  color: #1a5d1a;
  background-color: #f1c93b;
  padding: 10px;
  margin-bottom: 0;
}

.Nav {
  background-color: #fae392;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Nav a,
.Nav button {
  background-color: #4fa095;
  padding: 8px;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  margin-inline: 5px;
  border: none;
}
