.Units__Page {
  padding-block: 40px;
}

.Units__Page h2 {
  font-size: 18px;
}

#units-section {
  scroll-margin-top: 100px; /* Adjust this value as needed */
}

.Units__Page select {
  border-radius: 8px;
  padding: 12px;
  border: 1px solid lightgray !important;
  width: 100%;
}
