.Settings__Page {
  padding-block: 40px;
}

.Settings__Page h2 {
  font-size: 18px;
}

.Settings__Page section h4 {
  font-size: 16px;
}

.Settings__Page section > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: 1px solid black;
  border-radius: 8px;
  width: 100%;
  padding: 25px;
}

.Settings__Page section form {
  width: 80%;
}
