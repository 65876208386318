.Building__Form form {
  padding: 15px;
  border: 1px solid lightgray;
  border-radius: 8px;
  max-width: 600px;
  margin-inline: auto;
}

.Building__Form h3 {
  color: #4fa095;
  font-size: 16px;
  margin-bottom: 20px;
}

.Building__Form input,
.Building__Form select {
  border-radius: 8px;
  padding: 12px;
  border: 1px solid lightgray !important;
  width: 100%;
}

.Alert__Danger {
  max-width: 600px;
  margin-inline: auto;
  text-align: end;
}
