.Arrow__Icon {
  float: right;
  transition: transform 0.3s ease-in-out;
}

.accordion-item.Active .Arrow__Icon {
  transform: rotate(180deg);
}

.accordion-button button {
  text-decoration: none;
}

@media screen {
  .printOnly {
    display: none;
  }
}

@media print {
  .printOnly {
    display: block;
  }
}
