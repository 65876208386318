.Unit__Card {
  position: relative;
}

.Buttons__Sec {
  position: absolute;
  top: 10px;
  left: 10px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust opacity as needed */
  z-index: 1040; /* A value less than modal's z-index */
}
